import React from "react"
import { navigate } from "@gatsbyjs/reach-router"

import Layout from "../components/Layout"

export default function Error() {
  return (
    <Layout>
      <section className="section bg-dark-lighter text-white">
        <h2>404 Error</h2>
        <p className="my-4">This page is still in construction.</p>
        <button
          onClick={() => navigate(-1)}
          className="py-4 px-8 uppercase font-medium bg-primary rounded-md hover:bg-primary-lighter duration-300"
        >
          Go Back
        </button>
      </section>
    </Layout>
  )
}
